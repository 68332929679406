import classNames from 'classnames'
import React, { FC, HtmlHTMLAttributes } from 'react'

const ContentBox: FC<HtmlHTMLAttributes<HTMLDivElement>> = ({ className, children, ...props }) => {
  return (
    <div {...props} className={classNames('p-4 bg-gray-800 rounded-2xl', className)}>
      {children}
    </div>
  )
}

export default ContentBox
