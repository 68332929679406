import type { HeadFC } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'

import Button from '../components/Button'
import CustomerReview from '../components/CustomerReview'
import { LanguageHeaders } from '../components/LanguageHeaders'
import Layout from '../components/Layout'
import Link from '../components/Link'
import PricingTable from '../components/PricingTable'
import PricingVehicleIllustration from '../components/PricingVehicleIlustration'
import PricingHeadIllustration from '../components/pricingHeadIllustration'
import { useTranslate } from '../utils/translate'

const IndexPage = () => {
  const t = useTranslate()
  return (
    <Layout CTAvisible={false}>
      <main className="m-auto w-full lg:max-w-screen-lg grid grid-flow-row gap-20 lg:gap-56 p-4 lg:px-0 pt-24">
        <PricingHeadIllustration />
        <section className="z-10 grid grid-flow-row gap-6">
          <p className="subtitle text-secondary font-bold">{t('pricing.headline')}</p>
          <h1>{t('pricing.title')}</h1>
          <p className="subtitle lg:max-w-3xl">{t('pricing.subtitle')}</p>
          <Link to="/contact?q=demo">
            <Button className="dark:hover:bg-blue dark:hover:text-white">{t('requestDemo')}</Button>
          </Link>
        </section>
        <PricingTable />
        <section className="m-auto text-center">
          <PricingVehicleIllustration />
        </section>
      </main>
    </Layout>
  )
}

export default IndexPage

export const Head: HeadFC = ({ pageContext }: any) => {
  const language = pageContext.language
  const translations: any = pageContext.translations
  return (
    <>
      <title>{translations['pricing.meta.title']}</title>
      <meta name="description" content={translations['pricing.meta.description']} />
      <meta name="robots" content="index, follow" />
      <LanguageHeaders pathname={pageContext.defaultPath} locale={language} />
    </>
  )
}
