import { StaticImage } from 'gatsby-plugin-image'
import React, { FC, useLayoutEffect, useState } from 'react'

import { useTranslate } from '../utils/translate'
import Button from './Button'
import Link from './Link'

const PricingVehicleIllustration: FC = () => {
  const [width, setWidth] = useState(0)
  const t = useTranslate()

  useLayoutEffect(() => {
    if (!window) {
      return
    }
    const setW = () => {
      setWidth(window.innerWidth)
    }
    setW()

    window.addEventListener('resize', setW)

    return () => {
      window.removeEventListener('resize', setW)
    }
  }, [])

  const pointA = (width - 1024) / 2
  const pointB = (width - 1024) / 2 + 1024
  const curveRadius = 80

  return (
    <div className="mb-40">
      <div className="z-0 hidden sm:block absolute w-full overflow-hidden h-[350px] left-0 right-0 mt-44 md:mt-32 select-none">
        <div className={`absolute top-[180px] left-[calc(50%-142px)] w-[284px] h-[190px]`}>
          <StaticImage
            alt="van top view"
            src="../images/van_top.png"
            layout="fixed"
            quality={90}
            height={190}
            width={284}
          />
        </div>
        <svg width={width} viewBox={`0 0 ${width} 300`} fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d={`M0 140 H ${pointA - curveRadius} Q ${pointA} 140 ${pointA} ${280 - curveRadius} Q ${pointA} 280 ${
              pointA + curveRadius
            } 280 H ${pointB - curveRadius} Q ${pointB} 280 ${pointB} ${280 - curveRadius} V ${
              curveRadius + 20
            } Q ${pointB} 20 ${pointB + curveRadius} 20 H ${width}`}
            stroke="url(#paint0_radial_486_1049)"
            strokeWidth={curveRadius / 2}
          />

          <defs>
            <radialGradient
              id="paint0_radial_486_1049"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(-6 130) scale(830 5439.53)"
            >
              <stop stopColor="#00B6FF" stopOpacity="0" />
              <stop offset="1" stopColor="#1A4CFF" />
            </radialGradient>
          </defs>
        </svg>
      </div>
      <div className="relative z-2">
        <h4 className="text-secondary">{t('pricing.cta.subtitle')}</h4>
        <h1 className="mb-4">{t('pricing.cta.title')}</h1>
        <div className="m-auto flex flex-col md:flex-row justify-center items-center">
          <Link className="m-4" to="/contact?q=free-account">
            <Button className="dark:hover:bg-blue dark:hover:text-white">{t('requestFreeAccount')}</Button>
          </Link>
          <Link className="m-4" to="/contact">
            <Button variant="outlined">{t('contactSales')}</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default PricingVehicleIllustration
