import classNames from 'classnames'
import React, { FC, Fragment, useState } from 'react'
import { CgCheck } from 'react-icons/cg'
import { IoClose } from 'react-icons/io5'
import { useIntl } from 'react-intl'

import { ColorsGray, ColorsPrimary } from '../constants'
import translations from '../translations'
import Button from './Button'
import ContentBox from './ContentBox'
import Link from './Link'

const PricingTable: FC = () => {
  const [selectedPlanIndex, setSelectedPlanIndex] = useState(0)
  // @ts-ignore
  const content: typeof translations['en']['pricing.table'] = useIntl().messages['pricing.table']

  const plans = content.plans
  const featureBlocks = content.featureBlocks

  const plansCount = plans.length

  return (
    <div>
      <div className={classNames(`relative grid grid-flow-row gap-6 lg:gap-8`)}>
        <div className={classNames(`lg:hidden grid grid-flow-col gap-6 lg:gap-8 grid-cols-${plansCount}`)}>
          {plans.map((plan, index) => (
            <ContentBox
              className={classNames('col-span-1 p-2 cursor-pointer text-center', {
                ['text-secondary']: index !== selectedPlanIndex,
              })}
              onClick={() => setSelectedPlanIndex(index)}
              key={index}
            >
              <h4>{plan.title}</h4>
            </ContentBox>
          ))}
        </div>
        <ContentBox
          className={classNames(`lg:hidden sticky top-[var(--header-height)] self-start drop-shadow-lg-strong`)}
        >
          <h4>{plans[selectedPlanIndex].title}</h4>
          <p className="text-secondary">{plans[selectedPlanIndex].description}</p>
        </ContentBox>
        <div
          className={classNames(
            `hidden sticky top-[var(--header-height)] lg:grid grid-flow-col grid-cols-[minmax(324px,3fr)_1fr] auto-cols-[minmax(128px,_1fr)] gap-6 lg:gap-8 items-stretch bg-black drop-shadow-lg-strong pt-6`
          )}
        >
          <div></div>
          {plans.map((plan, index) => (
            <ContentBox key={index} className="p-6">
              <h4>{plan.title}</h4>
              <small className="text-secondary">{plan.description}</small>
            </ContentBox>
          ))}
        </div>
        <div className={classNames(``)}>
          {featureBlocks.map((block, blockIndex) => (
            <Fragment key={blockIndex}>
              <div
                key={`${blockIndex} - title`}
                className={classNames(
                  `group grid grid-flow-col grid-cols-[minmax(230px,_1fr)_100px] lg:grid-cols-[minmax(324px,3fr)_1fr] lg:auto-cols-[minmax(128px,_1fr)] gap-x-6 lg:gap-x-8 items-stretch`
                )}
              >
                <div className={classNames(`px-6 py-1 rounded-t-2xl pt-6 bg-gray-800 group-hover:bg-blue-dark`)}>
                  <p className="subtitle mb-4">{block.title}</p>
                </div>
                {plans.map((plan, planIndex) => (
                  <div
                    key={planIndex}
                    className={classNames(
                      `flex justify-center items-center px-6 py-1 rounded-t-2xl pt-6 bg-gray-800 group-hover:bg-blue-dark`,
                      { ['hidden lg:flex']: planIndex !== selectedPlanIndex }
                    )}
                  ></div>
                ))}
              </div>
              {block.features.map((feature, featureIndex) => (
                <div
                  key={`${blockIndex - featureIndex}`}
                  className={classNames(
                    `group grid grid-flow-col grid-cols-[minmax(230px,_1fr)_100px] lg:grid-cols-[minmax(324px,3fr)_1fr] lg:auto-cols-[minmax(128px,_1fr)] gap-x-6 lg:gap-x-8 items-stretch`
                  )}
                >
                  <div
                    className={classNames(`px-6 py-1 bg-gray-800 group-hover:bg-blue-dark`, {
                      ['rounded-b-2xl pb-6']: featureIndex + 1 === block.features.length,
                      ['mb-6 lg:mb-8']:
                        featureIndex + 1 === block.features.length && blockIndex + 1 !== featureBlocks.length,
                    })}
                  >
                    <p className="lg:ml-4 subtitle-2">{feature.title}</p>
                    {feature.description && (
                      <p className="lg:ml-4 mt-1 body-3 text-secondary">{feature.description || '&nbsp;'}</p>
                    )}
                  </div>
                  {feature.plans.map((plan, planIndex) => (
                    <div
                      key={planIndex}
                      className={classNames(
                        `flex justify-center items-center px-6 py-1 bg-gray-800 group-hover:bg-blue-dark`,
                        {
                          ['mb-6 lg:mb-8']:
                            featureIndex + 1 === block.features.length && blockIndex + 1 !== featureBlocks.length,
                          ['hidden lg:flex']: planIndex !== selectedPlanIndex,

                          ['rounded-b-2xl pb-6']: featureIndex + 1 === block.features.length,
                        }
                      )}
                    >
                      <FeaturePlan value={plan} />
                    </div>
                  ))}
                </div>
              ))}
            </Fragment>
          ))}
        </div>
      </div>
      <div className={classNames(`lg:hidden mt-6`)}>
        <Link to={`/contact?q=quote`}>
          <Button className="w-full dark:hover:bg-blue dark:hover:text-white">
            Get {plans[selectedPlanIndex].title}
          </Button>
        </Link>
      </div>
      <div
        className={classNames(
          `hidden top-[var(--header-height)] lg:grid grid-flow-col grid-cols-[minmax(324px,3fr)_1fr] auto-cols-[minmax(128px,_1fr)] gap-6 lg:gap-8 items-center mt-8`
        )}
      >
        <div></div>
        {plans.map((plan, index) => (
          <Link to={`/contact?q=quote`} key={index} className={classNames(`hidden lg:block`)}>
            <Button className="w-full dark:hover:bg-blue dark:hover:text-white">
              {content.get} {plan.title}
            </Button>
          </Link>
        ))}
      </div>
    </div>
  )
}

const FeaturePlan: FC<{ value: string }> = ({ value }) => {
  switch (value.toString()) {
    case 'v':
      return <CgCheck size={28} color={ColorsPrimary} />
    case 'x':
      return <IoClose size={20} color={ColorsGray} />
    default:
      return <span className="body-3 text-center">{value}</span>
  }
}
export default PricingTable
