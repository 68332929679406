import { StaticImage } from 'gatsby-plugin-image'
import React, { FC, useLayoutEffect, useRef, useState } from 'react'

const PricingHeadIllustration: FC = () => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [imageConstrains, setImageConstrains] = useState<{ scale: number; opacity: number }>({ scale: 1, opacity: 1 })

  useLayoutEffect(() => {
    if (!window) {
      return
    }
    window.addEventListener('scroll', () => {
      const imageHeight = containerRef.current?.getBoundingClientRect().height
      if (!imageHeight) {
        return
      }

      if (window.scrollY > imageHeight + 300) {
        return
      }

      const dimmingStartPosition = Math.floor(imageHeight * 0.6)
      const dimmingEndPosition = Math.floor(imageHeight)
      setImageConstrains({
        scale: 1 + window.scrollY / 6000,
        opacity:
          window.scrollY < dimmingStartPosition
            ? 1
            : (dimmingEndPosition - window.scrollY) / (dimmingEndPosition - dimmingStartPosition) || 0,
      })
    })
  }, [])

  return (
    <>
      <div className="lg:hidden select-none">
        <StaticImage
          alt="pricing illustration"
          src="../images/pricing_illustration_0.jpg"
          layout="constrained"
          quality={90}
          placeholder="none"
        />
      </div>
      <div className="hidden lg:block absolute z-0 w-full left-1/2 -translate-x-1/2 max-w-[1600px] pricing-illustration overflow-hidden">
        <div
          ref={containerRef}
          className="relative will-change-[opacity,transform]"
          style={{ transform: `scale(${imageConstrains.scale})`, opacity: imageConstrains.opacity }}
        >
          <StaticImage
            alt="pricing illustration 2"
            src="../images/pricing_illustration_1.jpg"
            layout="constrained"
            placeholder="none"
            quality={90}
          />
        </div>
      </div>
    </>
  )
}

export default PricingHeadIllustration
